import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image, Flex, Box } from './Base'
import SEO from './seo'

const Background = styled.div`
  height: 100vh;
  width: 100%;
  background-color: pink;
`
const RecipeDetails = styled.div`
  background-color: white;
  height: 100vh;
`
const Wrapper = styled.div`
  padding-right: 50px;
  padding-left: 50px;
`

const Meat = styled.div`
  width: 100%;
`
const Layout = ({ children, customSEO }) => {
  return (
    <Background>
      <RecipeDetails>{children}</RecipeDetails>
    </Background>
  )
}
Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool,
}

Layout.defaultProps = {
  customSEO: false,
}
export default Layout
